<template>
    <k-layout>
        <header class="tw-bg-white tw-px-4">
            <div class="tw-text-center tw-mx-auto tw-w-full tw-max-w-xl lg:tw-max-w-screen-lg tw-py-12 md:tw-pt-18 md:tw-pb-28">
                <h2 class="tw-text-gray-800 tw-text-5xl tw-leading-tight tw-font-bold tw-mb-6">
                    <span v-html="$t('resume.subtitles.modern_clear_cv')" />
                </h2>

                <p class="tw-mb-14 tw-text-xl tw-leading-7 tw-text-gray-800" v-html="$t('resume.descriptions.create_responsive_resume')" />

                <div class="tw-flex tw-items-center tw-justify-center tw-gap-4 tw-flex-col sm:tw-flex-row">
                    <k-button
                        id="gtm_create_cv"
                        :to="{ name: 'profile-cv', params: { locale: appLocale } }"
                        color="primary"
                        custom-class="tw-py-3 tw-px-10 tw-text-base"
                        class="tw-w-full sm:tw-w-auto"
                    >
                        {{ $t("resume.actions.create_resume") }}
                    </k-button>

                    <k-button
                        :to="{ name: 'cv-show', params: { locale: appLocale, id: 'info' } }"
                        color="secondary"
                        custom-class="tw-py-3 tw-px-10 tw-text-base"
                        class="tw-w-full sm:tw-w-auto"
                        target="_blank"
                    >
                        {{ $t("resume.actions.show_sample") }}
                    </k-button>
                </div>
            </div>
        </header>

        <main>
            <section class="section--first tw-relative tw-flex">
                <div class="tw-absolute tw-w-full tw-h-3/6 tw-bg-white tw-top-0 tw-left-0" />

                <div class="tw-flex tw-px-4 md:tw-px-10 tw-w-full tw-relative tw-z-10">
                    <div
                        class="tw-relative tw-flex tw-flex-col tw-items-center tw-justify-center tw-px-14 tw-py-8 tw-w-full tw-mx-auto tw-max-w-screen-xl tw-bg-koderia tw-rounded-2xl md:tw-flex-row md:tw-justify-start md:tw-items-end"
                    >
                        <img
                            src="../../assets/images/A4-Paper.jpg"
                            alt="a4-paper-in-hands"
                            aria-hidden="true"
                            class="tw-object-cover tw-top-0 tw-h-auto tw--mt-8 tw-right-0 md:tw-h-full md:tw-mt-0 md:tw-absolute md:tw-right-4 md:tw-hidden lg:tw-block"
                        />

                        <div class="tw-flex tw-justify-end tw-flex-col" style="max-width: 35rem">
                            <h2
                                class="tw-text-white tw-font-bold tw-leading-tight tw-text-4xl tw-text-center tw-z-10 md:tw-text-left"
                                v-html="$t('resume.subtitles.tailored_cv')"
                            />

                            <p class="tw-text-white tw-leading-7 tw-text-lg tw-mt-6">
                                {{ $t("resume.descriptions.tailored_cv") }}
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section class="tw-px-4 md:tw-px-10 tw-w-full">
                <div class="tw-flex tw-w-full tw-mx-auto tw-max-w-screen-xl">
                    <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-20">
                        <div class="tw-hidden md:tw-flex tw-items-center tw-h-screen tw-top-0 tw-sticky">
                            <transition name="fade" mode="out-in">
                                <img
                                    v-if="imageVisibleId === 'cv-landing__section-experience'"
                                    key="experience"
                                    :src="sections[0].imgSrc"
                                    alt="Unikátny graf"
                                />
                                <img v-if="imageVisibleId === 'cv-landing__section-print'" key="print" :src="sections[1].imgSrc" />
                                <img v-if="imageVisibleId === 'cv-landing__section-salary'" key="salary" :src="sections[2].imgSrc" />
                            </transition>
                        </div>

                        <div>
                            <div
                                v-for="section in sections"
                                :key="section.id"
                                :id="section.id"
                                class="tw-h-auto tw-flex tw-flex-col tw-justify-center tw-items-start tw-py-8 sm:tw-py-0 sm:tw-h-screen"
                            >
                                <p class="tw-text-gray-800 tw-font-bold tw-text-base tw-leading-7 tw-mb-4">
                                    {{ $t(section.sectionName) }}
                                </p>
                                <h3 class="tw-text-4xl tw-font-bold tw-text-gray-800 tw-mb-6">
                                    {{ $t(section.title) }}
                                </h3>
                                <p class="tw-text-lg tw-leading-7 tw-text-gray-800 tw-mb-8">
                                    {{ $t(section.description) }}
                                </p>

                                <img class="tw-block sm:tw-hidden" :src="section.imgSrc" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="tw-px-4 md:tw-px-10 tw-w-full">
                <div class="tw-mx-auto tw-w-full tw-max-w-screen-xl tw-pb-14">
                    <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-14">
                        <div v-for="(pro, id) in pros" :key="id" class="tw-flex tw-flex-col tw-items-center tw-text-center">
                            <img :src="pro.src" :alt="$t(pro.alt)" class="tw-mb-4 tw-w-12 tw-h-12" />
                            <h5 class="tw-font-bold tw-text-gray-800 tw-text-2xl tw-leading-normal tw-my-4">{{ $t(pro.title) }}</h5>
                            <p class="tw-text-gray-500 tw-text-base tw-leading-7" v-html="$t(pro.description)" />
                        </div>
                    </div>
                </div>
            </section>

            <section class="tw-bg-white tw-px-4 md:tw-px-10 tw-w-full">
                <div class="tw-mx-auto tw-w-full tw-max-w-screen-md">
                    <div class="tw-flex tw-flex-col tw-items-center tw-py-16 sm:tw-py-28">
                        <div class="tw-mb-20">
                            <div class="tw-mx-auto tw-text-center" style="max-width: 35rem;">
                                <h2 class="tw-text-gray-800 tw-text-4xl tw-leading-tight tw-font-bold">
                                    FAQ
                                </h2>

                                <p class="tw-mt-6 tw-text-gray-800 tw-text-base tw-leading-7" v-html="$t('resume.descriptions.faq')" />
                            </div>
                        </div>

                        <v-expansion-panels flat tile multiple>
                            <v-expansion-panel
                                v-for="(faq, id) in faqs"
                                :key="id"
                                class="tw-pb-6 tw-mb-6 tw-border-b-2 tw-border-black"
                                active-class="panel--active"
                            >
                                <v-expansion-panel-header
                                    class="tw-p-0 tw-outline-none tw-flex tw-items-center tw-justify-between panel-header"
                                    hide-actions
                                    @mouseover="addClass"
                                >
                                    <span class="tw-text-gray-800 tw-leading-7 tw-text-xl tw-font-bold">
                                        {{ faq.title }}
                                    </span>

                                    <span id="expand-icon" class="tw-flex-none tw-transition-all tw-duration-700 tw-ease-in-out">
                                        <k-icon name="plus" size="32" />
                                    </span>
                                </v-expansion-panel-header>

                                <v-expansion-panel-content class="panel-content">
                                    <div class="tw-text-gray-800 tw-text-base tw-leading-7" v-html="faq.description" />
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </div>
                </div>
            </section>

            <section class="tw-px-4 md:tw-px-10 tw-w-full">
                <div class="tw-mx-auto tw-w-full tw-max-w-screen-xl">
                    <div class="tw-pt-16 sm:tw-py-28">
                        <div class="tw-mb-20 tw-text-center">
                            <h2 class="tw-mb-6 tw-text-gray-800 tw-text-4xl tw-leading-tight tw-font-bold">
                                {{ $t("resume.subtitles.reviews") }}
                            </h2>

                            <p class="tw-text-gray-800 tw-text-lg tw-leading-7">
                                {{ $t("resume.descriptions.ratings") }}
                            </p>
                        </div>

                        <div class="masonry">
                            <div v-for="(rating, i) in ratings" :key="i" class="tw-bg-white tw-p-10 tw-rounded-2xl">
                                <div class="tw-mb-6 tw-flex tw-items-center tw-gap-x-1">
                                    <k-icon
                                        v-for="star in createStarArray(rating.rating)"
                                        :key="star.key"
                                        name="star"
                                        size="18"
                                        :class="{ 'tw-text-gray-900': star.isFilled, 'tw-text-gray-500': !star.isFilled }"
                                    />
                                </div>

                                <div class="tw-mb-6 tw-text-lg tw-leading-7 tw-text-gray-800">
                                    {{ rating.description }}
                                </div>

                                <div class="tw-flex tw-items-center">
                                    <div class="tw-mr-4">
                                        <div class="tw-w-12 tw-h-12 tw-rounded-full tw-bg-gray-300 tw-overflow-hidden">
                                            <img v-if="rating.avatar" :src="rating.avatar" />
                                        </div>
                                    </div>

                                    <div class="tw-flex tw-flex-col tw-leading-normal">
                                        <span class="tw-text-gray-800 tw-text-base tw-font-bold">{{ rating.name }}</span>
                                        <span class="tw-text-gray-500 tw-text-sm">{{ rating.position }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="tw-px-4 md:tw-px-10 tw-w-full tw-pb-16 sm:tw-pb-28">
                <div class="tw-mx-auto tw-w-full tw-max-w-screen-md tw-text-center">
                    <h2 class="tw-mb-6 tw-text-4xl tw-font-bold tw-leading-tight">
                        {{ $t("resume.subtitles.start_new_cv") }}
                    </h2>

                    <p class="tw-mb-6 tw-text-lg tw-leading-7">
                        {{ $t("resume.descriptions.start_new_cv") }}
                    </p>

                    <div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4 sm:tw-flex-row">
                        <k-button
                            :to="{ name: 'profile-cv', params: { locale: appLocale } }"
                            color="primary"
                            class="tw-w-full sm:tw-w-auto"
                            custom-class="tw-py-3 tw-px-10 tw-text-base"
                        >
                            {{ $t("resume.actions.create_resume") }}
                        </k-button>

                        <k-button
                            :to="{ name: 'cv-show', params: { locale: appLocale, id: 'info' } }"
                            color="secondary"
                            class="tw-w-full sm:tw-w-auto"
                            custom-class="tw-py-3 tw-px-10 tw-text-base"
                            target="_blank"
                        >
                            {{ $t("resume.actions.show_sample") }}
                        </k-button>
                    </div>
                </div>
            </section>
        </main>
    </k-layout>
</template>

<script>
import { getRouteLocale } from "@web/constants/language";
import IconLinkedin from "../assets/icon-linkedin.svg";
import IconResponsive from "../assets/icon-responsive.svg";
import IconLang from "./../assets/icon-lang.svg";
import IconPassword from "./../assets/icon-password.svg";
import IconEdit from "./../assets/icon-edit.svg";
import IconIT from "./../assets/icon-it.svg";
import DusanHAvatar from "./../assets/reviews/dusan-h-avatar.png";
import JakubHAvatar from "./../assets/reviews/jakub-h-avatar.jpeg";
import LenkaWAvatar from "./../assets/reviews/lenka-w-avatar.png";
import MichalVAvatar from "./../assets/reviews/michal-v-avatar.jpg";
import PavolOAvatar from "./../assets/reviews/pavol-o-avatar.jpg";
import RolandJAvatar from "./../assets/reviews/roland-j-avatar.png";
import Graph from "./../assets/cv-graph.svg";
import CV from "./../assets/cv-image.jpg";
import Money from "../assets/cv-money.png";

export default {
    data() {
        return {
            imageVisibleId: "cv-landing__section-experience",
            sections: [
                {
                    id: "cv-landing__section-experience",
                    sectionName: "resume.subheadings.experience_chart",
                    title: "resume.subtitles.unique_graph",
                    description: "resume.descriptions.understand_experiences",
                    imgSrc: Graph
                },
                {
                    id: "cv-landing__section-print",
                    sectionName: "resume.subheadings.print_share",
                    title: "resume.subtitles.ready_to_print",
                    description: "resume.descriptions.share_and_print",
                    imgSrc: CV
                },
                {
                    id: "cv-landing__section-salary",
                    sectionName: "resume.subheadings.adequate_salary",
                    title: "resume.subtitles.experience_salary",
                    description: "resume.descriptions.experience_salary",
                    imgSrc: Money
                }
            ],
            pros: [
                {
                    src: IconLinkedin,
                    alt: "resume.subheadings.linkedin",
                    title: "resume.subheadings.linkedin",
                    description: "resume.descriptions.linkedin_import"
                },
                {
                    src: IconResponsive,
                    alt: "resume.subheadings.responsive_design",
                    title: "resume.subheadings.responsive_design",
                    description: "resume.descriptions.displayed_on_device"
                },
                {
                    src: IconLang,
                    alt: "resume.subheadings.english_language",
                    title: "resume.subheadings.english_language",
                    description: "resume.descriptions.english_switch"
                },
                {
                    src: IconPassword,
                    alt: "resume.subheadings.password_protected",
                    title: "resume.subheadings.password_protected",
                    description: "resume.descriptions.intended_share"
                },
                {
                    src: IconEdit,
                    alt: "resume.subheadings.easy_edit",
                    title: "resume.subheadings.easy_edit",
                    description: "resume.descriptions.edit_freely"
                },
                {
                    src: IconIT,
                    alt: "resume.subheadings.it_sector_oriented",
                    title: "resume.subheadings.it_sector_oriented",
                    description: "resume.descriptions.unique_design"
                }
            ],
            ratings: [
                {
                    rating: 5,
                    description:
                        "Životopis bol prehľadný. Umožňoval rýchly náhľad počas editácie. Najviac sa mi páčilo zhrnutie zručností prezentované v grafe.",
                    name: "Lenka W.",
                    avatar: LenkaWAvatar,
                    position: "Fullstack developer"
                },
                {
                    rating: 5,
                    description:
                        "S vytvorením životopisu na Koderii mám veľmi pozitívnu skúsenosť. Vyplnenie údajov je veľmi rýchle a intuitívne. Oceňujem možnosť importu dát z Linked-inu. Pri nasledujúcom vytváraní životopisu určite znova využijem Koderiu.",
                    name: "Michal V.",
                    avatar: MichalVAvatar,
                    position: "Fullstack Developer"
                },
                {
                    rating: 5,
                    description:
                        "Vďaka Koderia CV som si dokázal rýchlejšie nájsť prácu, keďže vytváranie životopisu je veľmi intuitívne a rýchle, a zároveň znalosti sú prehľadne zhrnuté vo vygenerovanom grafe. Potencionálny zamestnávateľ Vás tak dokáže lepšie zaradiť na vhodnú pracovnú pozíciu a aj ohodnotiť. Prepojenie na ďalšie siete je taktiež bezproblémové.",
                    name: "Pavol O.",
                    avatar: PavolOAvatar,
                    position: "IT specialista"
                },
                {
                    rating: 4,
                    description: "Štrutkúra formulára dáva zmysel, je intuitívna a mám pocit, že obsahuje všetko podstatné.",
                    name: "Roland J.",
                    avatar: RolandJAvatar,
                    position: "IT špecialista"
                },
                {
                    rating: 5,
                    description: "Životopis sa vytvára veľmi príjemne a prehľadne. Výstupný formát a grafické zobrazenie je veľmi praktické.",
                    name: "Dušan H.",
                    avatar: DusanHAvatar,
                    position: "Backend Developer"
                },
                {
                    rating: 5,
                    description: "Vďaka Koderii mám životopis nielen plný informácií, ale aj krásne naformátovaný.",
                    name: "Jakub H.",
                    avatar: JakubHAvatar,
                    position: "Frontend Developer"
                }
            ]
        };
    },
    computed: {
        appLocale() {
            return getRouteLocale();
        },
        faqs() {
            return [
                {
                    title: this.$i18n.t("resume.subheadings.faq_1"),
                    description: this.$i18n.t("resume.descriptions.faq_1")
                },
                {
                    title: this.$i18n.t("resume.subheadings.faq_2"),
                    description: this.$i18n.t("resume.descriptions.faq_2")
                },
                {
                    title: this.$i18n.t("resume.subheadings.faq_3"),
                    description: this.$i18n.t("resume.descriptions.faq_3")
                },
                {
                    title: this.$i18n.t("resume.subheadings.faq_4"),
                    description: `
                        <p class="tw-mb-4">${this.$i18n.t("resume.descriptions.faq_4_1")}</p>

                        <ol class="tw-list-decimal tw-pl-8">
                            <li class="tw-mb-2">
                                <span class="tw-pl-2 tw-block">${this.$i18n.t("resume.descriptions.faq_4_2")}</span>
                            </li>
                            <li class="tw-mb-2">
                                <span class="tw-pl-2 tw-block">${this.$i18n.t("resume.descriptions.faq_4_3")}</span>
                            </li>
                            <li class="tw-mb-2">
                                <span class="tw-pl-2 tw-block">${this.$i18n.t("resume.descriptions.faq_4_4")}</span>
                            </li>
                            <li class="tw-mb-2">
                                <span class="tw-pl-2 tw-block">${this.$i18n.t("resume.descriptions.faq_4_5")}</span>
                            </li>
                        </ol>
                    `
                }
            ];
        }
    },
    mounted() {
        window.addEventListener("scroll", this.checkSection, true);
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.checkSection, true);
    },
    methods: {
        addClass(e) {
            if (!e.target.classList.contains("panel--not-active__hover")) {
                e.target.classList.add("panel--not-active__hover");

                setTimeout(() => {
                    e.target.classList.remove("panel--not-active__hover");
                }, 1200);
            }
        },
        createStarArray(number) {
            return Array.from({ length: 5 }, (_, index) => ({ key: index, isFilled: index < number }));
        },
        checkSection() {
            const elements = document.querySelectorAll('[id^="cv-landing__section-"]');

            if (elements && elements.length) {
                elements.forEach(element => {
                    const isVisible = this.isScrolledIntoView(element);

                    if (isVisible) {
                        this.imageVisibleId = element.id;
                    }
                });
            }
        },
        isScrolledIntoView(element) {
            const { top, bottom } = element.getBoundingClientRect();

            return top <= window.innerHeight / 2 && bottom >= window.innerHeight / 2;
        }
    }
};
</script>

<style lang="scss" scoped>
.section--first {
    min-height: min(450px, 34rem);
    aspect-ratio: 16/5.54;
    max-width: 100%;

    img {
        max-width: 615px;
    }

    @media screen and (max-width: 767px) {
        aspect-ratio: auto;

        img {
            max-width: 100%;
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.masonry {
    columns: 3;
    column-gap: 2rem;
    counter-reset: grid;

    & > div {
        break-inside: avoid;
        margin-bottom: 2rem;
    }

    @include -lg {
        columns: 2;
    }

    @include -md {
        columns: 1;
    }
}

::v-deep .panel--active {
    #expand-icon {
        animation: none !important;
        transform: rotate(135deg);
    }
}

::v-deep .panel--not-active__hover {
    #expand-icon {
        animation-duration: 1s;
        animation-name: bounce-icon;
    }
}

::v-deep .panel-header {
    min-height: 48px !important;
}

::v-deep .panel-content {
    & > div {
        padding: 0.65rem 0 1.5rem 0 !important;
    }
}

@keyframes bounce-icon {
    0% {
        transform: rotate(0);
    }

    50% {
        transform: rotate(22.5deg);
    }

    100% {
        transform: rotate(0);
    }
}
</style>
